export default {
  name: 'Unidades',
  components: {},
  meta () {
    return {
      title: 'Unidades - NetCarros',
      description: {
        name: 'description',
        content: 'Conheça as unidades da NetCarros'
      },
      property: {
        name: 'og:image',
        content: 'https://www.netcarros.com.br/seo/netcarros-vender.jpg'
      },
      link: {
        material: {
          rel: 'canonical',
          href: 'https://www.netcarros.com.br' + this.$route.fullPath
        }
      },
      meta: [
        { property: 'og:title', content: 'Unidades - NetCarros' },
        { name: 'description', content: 'Conheça as unidades da NetCarros' },
        { property: 'revisit-after', content: '5 days' },
        {
          property: 'og:image',
          content: 'https://www.netcarros.com.br/seo/netcarros-vender.jpg'
        },
        {
          property: 'og:image:secure_url',
          content: 'https://www.netcarros.com.br/seo/netcarros-vender.jpg'
        },
        {
          property: 'og:url',
          content: 'https://www.netcarros.com.br' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'NetCarros' },
        { property: 'fb:admins', content: '', vmid: 'fb:admins' },
        { property: 'og:locale', content: 'pt_BR' }
      ]
    }
  },
  data () {
    return {
      mounted: false,
      showMapDialog: false,
      iframeUrl: null,
      unidades: [
        {
          nome: 'NetCarros - São Paulo',
          endereco:
            'Av. Paulista, 1000 - Bela Vista, São Paulo - SP, 01310-100',
          telefone: '(11) 4003-4132',
          email: '',
          mapaUrl:
            'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.292383219665!2d-46.664260023789296!3d-23.521984060148412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5787a1014b3d%3A0xf58ab8d78c65d249!2sNetCarros%20-%20Barra%20Funda!5e0!3m2!1sen!2sbr!4v1708334443756!5m2!1sen!2sbr'
        }
      ]
    }
  },
  methods: {
    openMapDialog (url) {
      this.iframeUrl = url
      this.showMapDialog = true
    }
  },
  mounted () {
    this.linkpage =
      'https://api.whatsapp.com/send?phone=551151980247&text=Olá%20gostaria%20de%20saber%20com%20quais%20carros%20vcs%20trabalham?'
    document.querySelector('.botoes a').href = this.linkpage

    this.mounted = true
  }
}
